// add props to the treeData
import React, { useEffect, useState } from "react";
import { Card, Tree, TreeDataNode } from "antd";
import { gql, useMutation, useQuery } from "@apollo/client";
import { WorkStatus } from "../../gql/graphql";
import {
  SelectProcedureCycleStatus,
  SelectStepCycleStatus,
} from "../../components/SelectStatus";
import { CYCLE_DETAILS } from "../../shared/fragments";
import { Description } from "../../components/Description";
import {
  UPDATE_PROCEDURE_CYCLE,
  UPDATE_STEP_CYCLE,
} from "../../shared/mutations";
import StepCycleForm from "../forms/StepCycleForm";

const GET_CYCLE_INFO = gql`
  ${CYCLE_DETAILS}
  query getProcessCycleById($id: ID!) {
    processCycleById(id: $id) {
      ...CycleDetails
    }
  }
`;

export const UPDATE_STEP_CYCLES_STATUS = gql`
  mutation updateStepCyclesStatus($ids: [ID!]!, $status: WorkStatus!) {
    updateStepCyclesStatus(ids: $ids, status: $status) {
      id
      status
    }
  }
`;
export function useUpdateStepCyclesStatusMutation() {
  return useMutation(UPDATE_STEP_CYCLES_STATUS);
}

export const UPDATE_PROCEDURE_CYCLES_STATUS = gql`
  mutation updateProcedureCyclesStatus($ids: [ID!]!, $status: WorkStatus!) {
    updateProcedureCyclesStatus(ids: $ids, status: $status) {
      id
      status
    }
  }
`;
export function useUpdateProcedureCyclesStatus() {
  return useMutation(UPDATE_PROCEDURE_CYCLES_STATUS);
}

export function useUpdateStepCycle() {
  return useMutation(UPDATE_STEP_CYCLE);
}

export function useUpdateProcedureCycle() {
  return useMutation(UPDATE_PROCEDURE_CYCLE);
}

interface CycleTreeProps {
  processCycleId: string;
  checked: string[];
  expanded: string[];
  refetch?: () => void;
}
const CycleTree: React.FC<CycleTreeProps> = ({
  processCycleId,
  checked,
  expanded,
  refetch,
}) => {
  // Hooks
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
  const [checkedKeys, setCheckedKeys] = useState<React.Key[]>([]);
  const [treeKeys, setTreeKeys] = useState<TreeDataNode[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);
  const [autoExpandParent, setAutoExpandParent] = useState<boolean>(true);
  const [updateStepCyclesStatus] = useUpdateStepCyclesStatusMutation();
  const [updateProcedureCyclesStatus] = useUpdateProcedureCyclesStatus();
  const [updateStepCycle] = useUpdateStepCycle();
  const [updateProcedureCycle] = useUpdateProcedureCycle();

  const { loading, error, data } = useQuery(GET_CYCLE_INFO, {
    variables: { id: processCycleId },
  });

  useEffect(() => {
    setExpandedKeys(expanded);
  }, []);

  // Make sure the treeData, checked, and expanded are in sync with the component state
  useEffect(() => {
    setCheckedKeys(checked);
    setTreeKeys(
      data?.processCycleById.procedureCycles?.map((procedureCycle: any) => ({
        key: procedureCycle.id,
        title: (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: 400,
              }}
            >
              {procedureCycle.procedure?.title}
              <SelectProcedureCycleStatus
                procedureCycleId={procedureCycle.id}
                defaultValue={procedureCycle.status + ""}
                onSelectStatus={onSelectProcedureCycleStatus}
                size="small"
              />
            </div>
            <Card
              style={{
                visibility: selectedKeys.find((id) => id === procedureCycle.id)
                  ? "visible"
                  : "hidden",
                position: "absolute",
                zIndex: 2000,
              }}
              onClick={(e) => e.stopPropagation()}
            >
              <Description
                markdown={procedureCycle.description || ""}
                onChange={(markdown) => {
                  updateProcedureCycleDescription(procedureCycle.id, markdown);
                }}
              />
            </Card>
          </div>
        ),
        children: (procedureCycle.stepCycles || []).map((stepCycle: any) => ({
          key: stepCycle.id,
          title: (
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: 350,
                }}
              >
                {stepCycle.step?.title}
                <SelectStepCycleStatus
                  stepCycleId={stepCycle.id}
                  defaultValue={stepCycle.status + ""}
                  onSelectStatus={onSelectStepCycleStatus}
                  size="small"
                />
              </div>
              <Card
                style={{
                  visibility: selectedKeys.find((id) => id === stepCycle.id)
                    ? "visible"
                    : "hidden",
                  position: "absolute",
                  zIndex: 2000,
                }}
                onClick={(e) => e.stopPropagation()}
              >
                <StepCycleForm
                  selectedRow={stepCycle}
                  onSave={(formdata) =>
                    updateStepCycleDescription(
                      stepCycle.id,
                      formdata.description
                    )
                  }
                  onCancel={() => {
                    setSelectedKeys(
                      selectedKeys.filter((key) => key !== stepCycle.id)
                    );
                  }}
                />
              </Card>
            </div>
          ),
          onSelected: () => {
            console.log("selected", stepCycle.id);
          },
        })),
      })) || []
    );
  }, [data, checked, selectedKeys]);

  // If there is no data, return a message
  if (!data) {
    return <div>No data</div>;
  }

  // Event handlers
  const onExpand = (expandedKeysValue: React.Key[]) => {
    // if not set autoExpandParent to false, if children expanded, parent can not collapse.
    // or, you can remove all expanded children keys.
    setExpandedKeys(expandedKeysValue);
    setAutoExpandParent(false);
  };

  const updateStepCycleDescription = async (
    id: string,
    description: string
  ) => {
    const allStepCycles = data?.processCycleById.procedureCycles.flatMap(
      (procedureCycle: any) => procedureCycle.stepCycles
    );
    // Use find to locate the specific StepCycle object by its id
    const stepCycle = allStepCycles.find(
      (stepCycle: any) => stepCycle.id === id
    );
    if (!stepCycle) {
      console.error("StepCycle not found", id);
      return;
    }
    try {
      await updateStepCycle({
        variables: {
          id: id,
          status: stepCycle.status,
          description: description + "",
        },
      });
    } catch (error) {
      console.error(
        "Error updating step cycle " + id + " description to " + description,
        error
      );
    }
  };

  const updateProcedureCycleDescription = async (
    id: string,
    description: string
  ) => {
    const procedureCycle = data?.processCycleById.procedureCycles.find(
      (procedureCycle: any) => procedureCycle.id === id
    );
    if (!procedureCycle) {
      console.error("procedureCycle not found", id);
      return;
    }
    try {
      await updateProcedureCycle({
        variables: {
          id: id,
          status: procedureCycle.status,
          description: description,
        },
      });
    } catch (error) {
      console.error(
        "Error updating procedure cycle " +
          id +
          " description to " +
          description,
        error
      );
    }
  };

  const onSelectProcedureCycleStatus = async (
    ids: string[],
    status: string
  ) => {
    try {
      console.log("onSelectProcedureStatus", status);
      await updateProcedureCyclesStatus({
        variables: {
          ids: ids,
          status: status.replace(" ", "_"),
        },
      });
    } catch (error) {
      console.error(
        "Error updating procedure cycles " + ids + " status to " + status,
        error
      );
    }
  };

  const onSelectStepCycleStatus = async (ids: string[], status: string) => {
    try {
      console.log("onSelectStepStatus", status);
      await updateStepCyclesStatus({
        variables: {
          ids: ids,
          status: status.replace(" ", "_"),
        },
      });
      refetch && refetch();
    } catch (error) {
      console.error(
        "Error updating step cycles" + ids + " status to " + status,
        error
      );
    }
  };

  const onCheck = async (checkedKeysValue: React.Key[]) => {
    // check if new procedure cycles are checked
    const checkedProcedureCycle = checkedKeysValue.filter((key: any) =>
      treeKeys.find(
        (node) =>
          node?.key === key &&
          node.children?.length &&
          node.children?.length > 0 &&
          !checkedKeys.includes(key)
      )
    );

    if (checkedProcedureCycle.length === 1) {
      await updateProcedureCyclesStatus({
        variables: {
          ids: checkedProcedureCycle,
          status: WorkStatus.Done,
        },
      });
    }

    // find unchecked procedures
    const uncheckedProcedureCycle = checkedKeys.filter((key: any) =>
      treeKeys.find(
        (node) =>
          node?.key === key &&
          node.children?.length &&
          node.children?.length > 0 &&
          !checkedKeysValue.includes(key)
      )
    );

    if (uncheckedProcedureCycle.length === 1) {
      await updateProcedureCyclesStatus({
        variables: {
          ids: uncheckedProcedureCycle,
          status: WorkStatus.ToDo,
        },
      });
    }

    // make an array of all the children arrays in the treeData
    const allChildren = treeKeys.map((node) => node.children).flat();

    // find which keys ar iin checkedkeysvale but not in checkedkeys
    const checkedKeysToAdd = checkedKeysValue
      .filter((key) => !checkedKeys.includes(key))
      .filter((key: any) => allChildren.find((node) => node?.key === key)); // remove procedure (parent) keys
    // find which keys are in checkedkeys but not in checkedkeysvale
    const checkedKeysToRemove = checkedKeys
      .filter((key) => !checkedKeysValue.includes(key))
      .filter((key: any) => allChildren.find((node) => node?.key === key)); // remove procedure (parent) keys

    // if there are keys to add
    if (checkedKeysToAdd.length > 0) {
      try {
        await updateStepCyclesStatus({
          variables: {
            ids: checkedKeysToAdd,
            status: WorkStatus.Done,
          },
        });
      } catch (error) {
        console.error("Error updating step cycles status", error);
      }
    } else if (checkedKeysToRemove.length > 0) {
      // if there are keys to remove
      try {
        console.log("checkedKeysToRemove", checkedKeysToRemove);
        await updateStepCyclesStatus({
          variables: {
            ids: checkedKeysToRemove,
            status: WorkStatus.ToDo.replace(" ", "_"),
          },
        });
      } catch (error) {
        console.error(
          "Error updating step cycles status to To_Do",
          error,
          "ids",
          checkedKeysToRemove
        );
      }
    }
    setCheckedKeys(checkedKeysValue);
    refetch && refetch();
  };

  const onSelect = (selectedKeysValue: React.Key[], info: any) => {
    console.log("onSelect", info);
    setSelectedKeys(selectedKeysValue);
  };

  return (
    <>
      <style>
        {`
      .my-tree.ant-tree .ant-tree-checkbox {
        align-self: center;
      }
    `}
      </style>
      <Tree
        className="my-tree"
        checkable
        onExpand={onExpand}
        expandedKeys={expandedKeys}
        autoExpandParent={autoExpandParent}
        onCheck={(checked, info) => {
          // If 'checked' is an array, use it directly.
          // If 'checked' is an object, use the 'checked' property.
          const checkedKeysValue = Array.isArray(checked)
            ? checked
            : checked.checked;

          return onCheck(checkedKeysValue);
        }}
        checkedKeys={checkedKeys}
        onSelect={onSelect}
        selectedKeys={selectedKeys}
        treeData={treeKeys}
      />
    </>
  );
};

export default CycleTree;
