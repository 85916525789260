import { useState, useEffect } from "react";
import { Form, Input, Button } from "antd";
import { Description } from "../../components/Description";

interface StepCycleFormProps {
  onSave: (values: any) => void;
  onCancel: () => void;
  selectedRow: any; // Add selectedRow prop
}

export const StepCycleForm: React.FC<StepCycleFormProps> = ({
  onSave,
  onCancel,
  selectedRow,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (selectedRow) {
      // If in edit mode and selectedRow exists, pre-populate form fields with selectedRow data
      form.setFieldsValue({
        id: selectedRow.id,
        desciption: selectedRow.description,
        // Add other fields as needed
      });
    }
  }, [selectedRow]);

  const handleSave = async () => {
    try {
      const values = await form.validateFields();
      if (values.description === undefined) {
        // component turns markdown to undefined if not edited
        values.description = selectedRow.description;
      }
      onSave(values);
      onCancel();
    } catch (error) {
      console.error("Validation error:", error);
    }
  };

  return (
    <div>
      <Form form={form} layout="vertical">
        <Form.Item name="id" style={{ display: "none" }}>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item label="Description" name="description" rules={[{}]}>
          <Description
            markdown={selectedRow.description || ""}
            onChange={(markdown) => {
              form.setFieldsValue({ description: markdown });
            }}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" onClick={handleSave} size="small">
            Save
          </Button>
          <Button onClick={onCancel} size="small" style={{ marginLeft: 8 }}>
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default StepCycleForm;
