import { gql } from "graphql-tag";
import { CYCLE_DETAILS } from "./fragments";

export const CREATE_PROCESS = gql`
  mutation createProcess(
    $title: String!
    $description: String!
    $spaceId: ID!
    $priority: WorkPriority!
    $deadline_uom: Frequency
    $deadline_units: Int
    $json: String
  ) {
    createProcess(
      title: $title
      description: $description
      spaceId: $spaceId
      priority: $priority
      deadline_uom: $deadline_uom
      deadline_units: $deadline_units
      json: $json
    ) {
      id
      title
      description
      priority
      deadline_uom
      deadline_units
    }
  }
`;

export const UPDATE_PROCESS = gql`
  mutation updateProcess(
    $id: ID!
    $title: String!
    $description: String!
    $priority: WorkPriority!
    $deadline_uom: Frequency
    $deadline_units: Int
  ) {
    updateProcess(
      id: $id
      title: $title
      description: $description
      priority: $priority
      deadline_uom: $deadline_uom
      deadline_units: $deadline_units
    ) {
      id
      title
      description
      priority
      deadline_uom
      deadline_units
    }
  }
`;

export const CREATE_TRIGGER_SCHEDULE = gql`
  mutation createTriggerSchedule(
    $processId: ID!
    $frequency: String!
    $timeOfDay: [String]!
    $dayOfWeek: [String]!
    $dayOfMonth: [Int]!
    $monthOfYear: [String]!
  ) {
    createTriggerSchedule(
      processId: $processId
      frequency: $frequency
      timeOfDay: $timeOfDay
      dayOfWeek: $dayOfWeek
      dayOfMonth: $dayOfMonth
      monthOfYear: $monthOfYear
    ) {
      id
      frequency
      timeOfDay
      dayOfWeek
      dayOfMonth
      monthOfYear
    }
  }
`;

export const UPDATE_TRIGGER_SCHEDULE = gql`
  mutation updateTriggerSchedule(
    $id: ID!
    $frequency: String!
    $timeOfDay: [String]!
    $dayOfWeek: [String]!
    $dayOfMonth: [Int]!
    $monthOfYear: [String]!
  ) {
    updateTriggerSchedule(
      id: $id
      frequency: $frequency
      timeOfDay: $timeOfDay
      dayOfWeek: $dayOfWeek
      dayOfMonth: $dayOfMonth
      monthOfYear: $monthOfYear
    ) {
      id
      frequency
      timeOfDay
      dayOfWeek
      dayOfMonth
      monthOfYear
    }
  }
`;

export const DELETE_TRIGGER_SCHEDULE = gql`
  mutation deleteTriggerSchedule($id: ID!) {
    deleteTriggerSchedule(id: $id)
  }
`;

export const CREATE_PROCESS_CYCLE = gql`
  ${CYCLE_DETAILS}
  mutation createProcessCycle(
    $processId: ID!
    $title: String!
    $status: WorkStatus!
    $priority: WorkPriority!
  ) {
    createProcessCycle(
      processId: $processId
      title: $title
      status: $status
      priority: $priority
    ) {
      ...CycleDetails
    }
  }
`;

export const UPDATE_PROCESS_CYCLE = gql`
  ${CYCLE_DETAILS}
  mutation upateProcessCycle(
    $id: ID!
    $title: String!
    $status: WorkStatus!
    $priority: WorkPriority!
    $description: String!
    $deadline: String
  ) {
    updateProcessCycle(
      id: $id
      title: $title
      status: $status
      priority: $priority
      description: $description
      deadline: $deadline
    ) {
      ...CycleDetails
    }
  }
`;

export const CREATE_PROCEDURE = gql`
  mutation CreateProcedure(
    $title: String!
    $description: String
    $processId: ID!
    $priority: WorkPriority
  ) {
    createProcedure(
      title: $title
      description: $description
      processId: $processId
      priority: $priority
    ) {
      id
      title
      description
      processId
      priority
    }
  }
`;

export const UPDATE_PROCEDURE = gql`
  mutation UpdateProcedure(
    $id: ID!
    $title: String!
    $description: String
    $priority: WorkPriority
  ) {
    updateProcedure(
      id: $id
      title: $title
      description: $description
      priority: $priority
    ) {
      id
      title
      description
      processId
      priority
    }
  }
`;

export const UPDATE_PROCEDURE_CYCLE = gql`
  mutation upateProcedureCycle(
    $id: ID!
    $status: WorkStatus!
    $description: String!
  ) {
    updateProcedureCycle(id: $id, status: $status, description: $description) {
      id
      status
      description
    }
  }
`;

export const CREATE_STEP = gql`
  mutation CreateStep(
    $title: String!
    $description: String
    $procedureId: ID!
  ) {
    createStep(
      title: $title
      description: $description
      procedureId: $procedureId
    ) {
      id
      title
      description
      procedureId
    }
  }
`;

export const UPDATE_STEP = gql`
  mutation UpdateStep($id: ID!, $title: String!, $description: String) {
    updateStep(id: $id, title: $title, description: $description) {
      id
      title
      description
    }
  }
`;

export const UPDATE_STEP_CYCLE = gql`
  mutation upateStepCycle(
    $id: ID!
    $status: WorkStatus!
    $description: String!
  ) {
    updateStepCycle(id: $id, status: $status, description: $description) {
      id
      status
      description
    }
  }
`;
