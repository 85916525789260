import { useState, useEffect } from "react";
import { Form, Input, Button, Select } from "antd";
import { WorkPriority } from "../../gql/graphql";
import { Description } from "../../components/Description";

const { Option } = Select;
interface ProcedureFormProps {
  onSave: (values: any) => void;
  onCancel: () => void;
  initialState: "ADD" | "EDIT"; // Specify the possible values for initialState
  selectedRow: any; // Add selectedRow prop
}

export const ProcedureForm: React.FC<ProcedureFormProps> = ({
  onSave,
  onCancel,
  initialState,
  selectedRow,
}) => {
  const [form] = Form.useForm();
  const [isEditing, setIsEditing] = useState(initialState === "EDIT");

  useEffect(() => {
    if (initialState === "EDIT" && selectedRow) {
      // If in edit mode and selectedRow exists, pre-populate form fields with selectedRow data
      form.setFieldsValue({
        id: selectedRow.id,
        title: selectedRow.title,
        priority: selectedRow.priority,
        description: selectedRow.description,
        // Add other fields as needed
      });
    } else {
      form.setFieldsValue({
        id: null,
        title: "",
        priority: WorkPriority.Medium,
        description: "",
        // Add other fields as needed
      });
    }
  }, [initialState, selectedRow]);

  const handleSave = async () => {
    try {
      const values = await form.validateFields();
      onSave(values);
      onCancel();
    } catch (error) {
      console.error("Validation error:", error);
    }
  };

  return (
    <Form form={form} layout="vertical">
      <Form.Item name="id" style={{ display: "none" }}>
        <Input type="hidden" />
      </Form.Item>
      <Form.Item
        label="Title"
        name="title"
        rules={[{ required: true, message: "Please enter a title" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Description" name="description" rules={[]}>
        <Description
          markdown={selectedRow?.description ?? ""}
          onChange={(markdown) => {
            form.setFieldsValue({ description: markdown });
          }}
        />
      </Form.Item>
      <Form.Item
        label="Default Priority"
        name="priority"
        initialValue="Medium" // Set the default value to "Medium"
        rules={[{ required: true, message: "Please select a priority" }]}
      >
        <Select>
          <Option value="Low">Low</Option>
          <Option value="Medium">Medium</Option>
          <Option value="High">High</Option>
          <Option value="Critical">Critical</Option>
        </Select>
      </Form.Item>
      <Form.Item>
        <Button type="primary" onClick={handleSave}>
          {isEditing ? "Save Changes" : "Add Procedure"}
        </Button>
        <Button onClick={onCancel} style={{ marginLeft: 8 }}>
          Cancel
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ProcedureForm;
