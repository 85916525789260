import React from "react";

interface ProcessContextProps {
  process: {
    cProcessId: string;
    cProcessName: string;
  };
  setProcess: React.Dispatch<
    React.SetStateAction<{ cProcessId: string; cProcessName: string }>
  >;
}

export const ProcessContext = React.createContext<ProcessContextProps>({
  process: {
    cProcessId: "",
    cProcessName: "",
  },
  setProcess: () => {},
});
