import { Checkbox } from "antd";
import { useState } from "react";

const daysOfWeekOptions = [
  { label: "Sun", value: "SUNDAY" },
  { label: "Mon", value: "MONDAY" },
  { label: "Tue", value: "TUESDAY" },
  { label: "Wed", value: "WEDNESDAY" },
  { label: "Thu", value: "THURSDAY" },
  { label: "Fri", value: "FRIDAY" },
  { label: "Sat", value: "SATURDAY" },
];

// Usage with Ant Design Checkbox Group
<Checkbox.Group options={daysOfWeekOptions} /* other props */ />;

// Correctly destructure props here
const DaysOfWeek: React.FC<{
  days: string[];
  onChange: (checkedValues: string[]) => void;
}> = ({ days, onChange }) => {
  // You can initialize selectedDays with the days prop if you want the checkboxes to reflect an initial state
  const [selectedDays, setSelectedDays] = useState(days);

  const handleChange = (checkedValues: string[]) => {
    setSelectedDays(checkedValues);
    if (typeof onChange === "function") {
      onChange(checkedValues);
    }
  };

  return (
    <Checkbox.Group
      options={daysOfWeekOptions}
      value={selectedDays}
      onChange={handleChange}
    />
  );
};

export default DaysOfWeek;
