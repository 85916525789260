import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import { gql } from "@apollo/client";
import { logToServer } from "../shared/utils";

export function signInWithGoogle(client: any) {
  return new Promise((resolve, reject) => {
    const provider = new GoogleAuthProvider();

    provider.addScope("https://www.googleapis.com/auth/userinfo.email");
    provider.addScope("https://www.googleapis.com/auth/userinfo.profile");

    provider.setCustomParameters({
      access_type: "offline",
      prompt: "consent",
    });

    const auth = getAuth();
    signInWithPopup(auth, provider)
      .then((result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential?.accessToken ?? null;
        logToServer("token", { token });

        const user = result.user;
        logToServer("user", { user });

        const query = gql`
          query LoginGoogleUser(
            $email: String!
            $googleId: String!
            $googleToken: String!
          ) {
            loginGoogleUser(
              email: $email
              googleId: $googleId
              googleToken: $googleToken
            ) {
              id
              email
              firstName
              lastName
              imageUrl
              token
            }
          }
        `;

        return client.query({
          query,
          variables: {
            email: user.email,
            googleId: user.uid,
            googleToken: token,
          },
          fetchPolicy: "network-only",
        });
      })
      .then((queryResult) => {
        logToServer("loginGoogleUser", queryResult);
        localStorage.setItem("token", queryResult.data.loginGoogleUser.token);
        localStorage.setItem("tenant", queryResult.data.loginGoogleUser.id);
        resolve(queryResult.data.loginGoogleUser);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.customData?.email;
        const credential = GoogleAuthProvider.credentialFromError(error);

        logToServer("Error in login", { errorCode, errorMessage, email });
        reject(error);
      });
  });
}
