import { useMutation, useApolloClient, createHttpLink } from "@apollo/client";
import { CREATE_PROCESS, UPDATE_PROCESS } from "../../shared/mutations";
import { Process } from "../../gql/graphql";
import { client } from "./client";

const hasUnits = (units: number | undefined) => {
  return units !== undefined && units > 0;
};
const timeperioidToFrequency = (timeperiod: string | undefined) => {
  switch (timeperiod) {
    case "Hours":
      return "Hourly";
    case "Days":
      return "Daily";
    case "Weeks":
      return "Weekly";
    case "Months":
      return "Monthly";
    case "Years":
      return "Yearly";
    default:
      return "Daily";
  }
};

export const createProcess = async (process: any) => {
  const result = await client.mutate({
    mutation: CREATE_PROCESS,
    variables: {
      title: process.title,
      description: process.description,
      spaceId: process.spaceId,
      priority: process.priority,
      deadline_uom: hasUnits(process.deadline_units ?? undefined)
        ? timeperioidToFrequency(process.deadline_uom ?? undefined)
        : null,
      deadline_units: hasUnits(process.deadline_units ?? undefined)
        ? process.deadline_units
        : null,
      json: process.json ?? undefined,
    },
  });
  return result;
};

export const updateProcess = async (process: Process) => {
  const result = await client.mutate({
    mutation: UPDATE_PROCESS,
    variables: {
      id: process.id, // Provide the process ID to identify which process to update
      title: process.title,
      description: process.description || "",
      priority: process.priority,
      deadline_uom: hasUnits(process.deadline_units ?? undefined)
        ? timeperioidToFrequency(process.deadline_uom ?? undefined)
        : null,
      deadline_units: hasUnits(process.deadline_units ?? undefined)
        ? process.deadline_units
        : null,
    },
  });
  return result;
};
