import React from "react";
import { Modal, Button } from "antd";

interface DialogBoxProps {
  severity: "Confirm" | "Warn" | "Danger";
  title: string;
  description: string;
  confirmButtonText: string;
  executeFunction: () => void;
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

const DialogBox: React.FC<DialogBoxProps> = ({
  severity,
  title,
  description,
  confirmButtonText,
  executeFunction,
  visible,
  setVisible,
}) => {
  const handleOk = () => {
    executeFunction();
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const getButtonType = () => {
    switch (severity) {
      case "Confirm":
        return "primary";
      default:
        return "default";
    }
  };

  const isDanger = () => {
    return severity === "Danger";
  };

  return (
    <Modal
      title={title}
      zIndex={10000}
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type={getButtonType()}
          danger={isDanger()}
          onClick={handleOk}
        >
          {confirmButtonText}
        </Button>,
      ]}
    >
      <p>{description}</p>
    </Modal>
  );
};

export default DialogBox;
