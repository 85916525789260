import {
  GoogleAuthProvider,
  getAuth,
  getRedirectResult,
  signInWithRedirect,
} from "firebase/auth";
import { gql } from "@apollo/client";
import { logToServer } from "../shared/utils";
import { FirebaseApp } from "firebase/app";

export function addonSignInWithGoogle(client: any, app: FirebaseApp) {
  return new Promise((resolve, reject) => {
    const provider = new GoogleAuthProvider();

    provider.addScope("email");
    provider.addScope("profile");

    provider.setCustomParameters({
      prompt: "consent",
    });

    const auth = getAuth(app);
    logToServer("auth", auth);

    getRedirectResult(auth)
      .then((result) => {
        console.log("result", result);
        if (result) {
          const credential = GoogleAuthProvider.credentialFromResult(result);
          const token = credential?.accessToken ?? null;
          logToServer("token", { token });

          const user = result.user;
          logToServer("user", { user });

          const query = gql`
            query LoginGoogleUser(
              $email: String!
              $googleId: String!
              $googleToken: String!
            ) {
              loginGoogleUser(
                email: $email
                googleId: $googleId
                googleToken: $googleToken
              ) {
                id
                email
                firstName
                lastName
                imageUrl
                token
              }
            }
          `;
          logToServer("query", {
            email: user.email,
            googleId: user.uid,
            googleToken: token,
          });
          return client.query({
            query,
            variables: {
              email: user.email,
              googleId: user.uid,
              googleToken: token,
            },
            fetchPolicy: "network-only",
          });
        } else {
          // No result, initiate the sign-in process
          signInWithRedirect(auth, provider);
          throw new Error("Redirecting to Google sign-in");
        }
      })
      .then((queryResult) => {
        if (
          queryResult &&
          queryResult.data &&
          queryResult.data.loginGoogleUser
        ) {
          logToServer("loginGoogleUser", queryResult);
          localStorage.setItem("token", queryResult.data.loginGoogleUser.token);
          localStorage.setItem("tenant", queryResult.data.loginGoogleUser.id);
          resolve(queryResult.data.loginGoogleUser);
        } else {
          reject("Invalid response from loginGoogleUser query");
        }
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.customData?.email;
        const credential = GoogleAuthProvider.credentialFromError(error);

        logToServer("Error in login", { errorCode, errorMessage, email });
        reject(error);
      });
  });
}
