import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { gql } from "graphql-tag";
import { Procedure } from "../../gql/graphql";
import {
  ProceduresTable,
  ProcedureTableDataType,
} from "../tables/ProceduresTable";
import { Button, Card, Descriptions, Modal, Space, Tabs } from "antd";
import CyclesTable from "../tables/CyclesTable";
import { useContext, useEffect, useState } from "react";
import { ProcessContext } from "../../contexts/ProcessContext";
import { SpaceContext } from "../../contexts/SpaceContext";
import TriggerScheduleForm from "../forms/TriggerScheduleForm";
import {
  CREATE_TRIGGER_SCHEDULE,
  UPDATE_TRIGGER_SCHEDULE,
  DELETE_TRIGGER_SCHEDULE,
} from "../../shared/mutations";
import ProcessForm from "../forms/ProcessForm";
import { updateProcess } from "../operations/process";
import ButtonGroup from "antd/es/button/button-group";

// Define the GraphQL query
const GET_PROCESS_INFO = gql`
  query GetProcessInfo($processId: ID!) {
    processById(id: $processId) {
      id
      title
      spaceId
      spaceTitle
      priority
      description
      deadline_units
      deadline_uom
      procedures {
        id
        title
        description
        priority
      }
      triggerSchedule {
        id
        frequency
        dayOfWeek
        dayOfMonth
        monthOfYear
        timeOfDay
      }
    }
  }
`;

function useCreateTriggerSchedule() {
  return useMutation(CREATE_TRIGGER_SCHEDULE);
}

function useUpdateTriggerSchedule() {
  return useMutation(UPDATE_TRIGGER_SCHEDULE);
}

function useDeleteTriggerSchedule() {
  return useMutation(DELETE_TRIGGER_SCHEDULE);
}

const ModalStates = {
  OFF: "OFF",
  ADD: "ADD",
  EDIT: "EDIT",
};

const env = process.env;

export function ProcessDetails() {
  const { processId } = useParams(); // Get the processId from the route parameters
  const [processModalState, setProcessModalState] = useState(ModalStates.OFF);
  const [triggerModalState, setTriggerModalState] = useState(ModalStates.OFF);
  const [createTriggerSchedule, { data: createTriggerScheduleData }] =
    useCreateTriggerSchedule();
  const [updateTriggerSchedule, { data: updateTriggerScheduleData }] =
    useUpdateTriggerSchedule();
  const [deleteTriggerSchedule, { data: deleteTriggerScheduleData }] =
    useDeleteTriggerSchedule();
  // Use the useQuery hook to fetch data
  const { loading, error, data, refetch } = useQuery(GET_PROCESS_INFO, {
    variables: { processId },
  });
  const { space, setSpace } = useContext(SpaceContext);
  const { process, setProcess } = useContext(ProcessContext);

  useEffect(() => {
    if (data) {
      setSpace({
        cSpaceId: data.processById.spaceId,
        cSpaceName: data.processById.spaceTitle,
      });
      setProcess({
        cProcessId: processId ? processId : "",
        cProcessName: data?.processById.title,
      });
    }
  }, [data]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const tableData: ProcedureTableDataType[] = data.processById.procedures.map(
    (procedure: Procedure) => ({
      key: procedure.id,
      id: procedure.id,
      title: procedure.title,
      priority: procedure.priority,
      description: procedure.description,
    })
  );

  const handleCreateTriggerSchedule = () => {
    setTriggerModalState(ModalStates.ADD);
  };
  const handleCancel = () => {
    setTriggerModalState(ModalStates.OFF);
    setProcessModalState(ModalStates.OFF);
  };

  const handleSaveProcess = async (process: any) => {
    try {
      const result = await updateProcess(process);

      // Handle the result if needed
      console.log("Mutation result:", result);
    } catch (error) {
      // Handle the error if the mutation fails
      console.error("Mutation error:", error);
    }
    refetch();
    setProcessModalState(ModalStates.OFF);
  };

  const handleSaveTriggerSchedule = async (triggerSchedule: any) => {
    console.log("TriggerSchedule:", triggerSchedule);
    if (triggerModalState === ModalStates.ADD) {
      try {
        const result = await createTriggerSchedule({
          variables: {
            frequency: triggerSchedule.frequency,
            dayOfWeek: triggerSchedule.dayOfWeek,
            dayOfMonth: triggerSchedule.dayOfMonth,
            monthOfYear: triggerSchedule.monthOfYear,
            timeOfDay: triggerSchedule.timeOfDay,
            processId: processId,
          },
        });

        console.log("result", result.data.createTriggerScheduleData);

        // Handle the result if needed
        console.log("Mutation result:", result);
      } catch (error) {
        // Handle the error if the mutation fails
        console.error("Mutation error:", error);
      }
    } else if (triggerModalState === ModalStates.EDIT) {
      try {
        const result = await updateTriggerSchedule({
          variables: {
            id: triggerSchedule.triggerId,
            frequency: triggerSchedule.frequency,
            dayOfWeek: triggerSchedule.dayOfWeek,
            dayOfMonth: triggerSchedule.dayOfMonth,
            monthOfYear: triggerSchedule.monthOfYear,
            timeOfDay: triggerSchedule.timeOfDay,
          },
        });

        // Handle the result if needed
        console.log("Mutation result:", result);
      } catch (error) {
        // Handle the error if the mutation fails
        console.error("Mutation error:", error);
      }
    }
    refetch();
    setTriggerModalState(ModalStates.OFF);
  };

  async function handleDeleteScheule() {
    const result = await deleteTriggerSchedule({
      variables: {
        id: data.processById.triggerSchedule.id,
      },
    });
    refetch();
  }

  const ProceduresTab = (
    <Tabs.TabPane tab="Procedures" key="1">
      <div>
        <ProceduresTable
          dataSource={tableData}
          title="Procedures"
          processId={processId ? processId : ""}
        />
      </div>
    </Tabs.TabPane>
  );
  const CyclesTab = (
    <Tabs.TabPane tab="Cycles" key="2">
      <CyclesTable id={processId ? processId : ""} cyclesBy="PROCESS" />
    </Tabs.TabPane>
  );

  // Display the process details here
  return (
    <div>
      <Card
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "10px",
            }}
          >
            <span>Process Details</span>
            <Button onClick={() => setProcessModalState(ModalStates.EDIT)}>
              Edit
            </Button>
          </div>
        }
      >
        <Descriptions
          title="Process"
          items={[
            { label: "Title", children: data.processById.title },
            { label: "Space", children: data.processById.spaceTitle },
            { label: "Priority", children: data.processById.priority },
          ]}
        />
        {data.processById.description && (
          <Descriptions
            title="Description"
            items={[{ children: data.processById.description }]}
          />
        )}
        <Descriptions
          title="Trigger"
          items={[
            data.processById.triggerSchedule?.frequency
              ? {
                  label: "Frequency",
                  labelStyle: {
                    paddingTop: "4px",
                  },
                  children: (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {data.processById.triggerSchedule.frequency}
                      <ButtonGroup>
                        <Button
                          type="link"
                          onClick={() => setTriggerModalState(ModalStates.EDIT)}
                        >
                          Edit
                        </Button>
                        <Button
                          type="link"
                          onClick={() => handleDeleteScheule()}
                        >
                          Delete
                        </Button>
                      </ButtonGroup>
                    </div>
                  ),
                }
              : {
                  label: "Status",
                  labelStyle: {
                    paddingTop: "4px",
                  },
                  children: (
                    <span>
                      Off
                      <Button
                        type="link"
                        onClick={() => handleCreateTriggerSchedule()}
                      >
                        Create
                      </Button>
                    </span>
                  ),
                },
          ]}
        />
      </Card>
      <Modal
        zIndex={10000}
        title={"Edit Process"}
        open={processModalState !== ModalStates.OFF}
        onCancel={handleCancel}
        footer={null}
      >
        <ProcessForm
          onSave={handleSaveProcess}
          onCancel={handleCancel}
          initialState={"EDIT"}
          selectedRow={data.processById}
        />
      </Modal>
      <Modal
        zIndex={10000}
        title={
          triggerModalState === ModalStates.ADD
            ? "Create Trigger"
            : "Edit Trigger"
        }
        open={triggerModalState !== ModalStates.OFF}
        onCancel={handleCancel}
        footer={null}
      >
        <TriggerScheduleForm
          onSave={handleSaveTriggerSchedule}
          onCancel={handleCancel}
          initialState={triggerModalState === ModalStates.ADD ? "ADD" : "EDIT"}
          data={data.processById}
        />
      </Modal>

      <Tabs defaultActiveKey="2" type="line">
        {CyclesTab}
        {ProceduresTab}
      </Tabs>
    </div>
  );
}

export default ProcessDetails;
