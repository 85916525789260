import { gql } from "@apollo/client";

export const CYCLE_DETAILS = gql`
  fragment CycleDetails on ProcessCycle {
    id
    title
    status
    priority
    deadline
    description
    process {
      id
      title
      spaceId
      spaceTitle
      triggerSchedule {
        id
        frequency
      }
    }
    procedureCycles {
      id
      status
      description
      priority
      procedure {
        id
        title
      }
      stepCycles {
        id
        status
        description
        step {
          id
          title
        }
      }
    }
  }
`;
