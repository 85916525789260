import React, { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { gql } from "graphql-tag";
import { Space } from "../../gql/graphql";
import { Card } from "antd";
import { Link } from "react-router-dom";
import { SpaceContext } from "../../contexts/SpaceContext";
import CyclesTable from "../tables/CyclesTable";
import { ProcessContext } from "../../contexts/ProcessContext";

const GET_TENANT_SPACES = gql`
  query GetTenantInfo($tenantId: ID!) {
    tenantById(id: $tenantId) {
      title
      spaces {
        id
        title
        description
      }
    }
  }
`;

// Create the component
export function TenantSpaces() {
  const tenantId: string | null = localStorage.getItem("tenant");
  const { space, setSpace } = React.useContext(SpaceContext);
  const { process, setProcess } = React.useContext(ProcessContext);

  // Use the useQuery hook to fetch data
  const { loading, error, data } = useQuery(GET_TENANT_SPACES, {
    variables: { tenantId },
  });

  useEffect(() => {
    if (data) {
      setSpace({ cSpaceId: "", cSpaceName: "" });
      setProcess({ cProcessId: "", cProcessName: "" });
    }
  }, [data]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const tenant = data.tenantById;

  return (
    <div>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {tenant.spaces.map((space: Space) => (
          <Link to={`/space/${space.id}`} key={space.id}>
            <Card
              key={space.id}
              title={space.title}
              style={{ width: 300, margin: "16px" }}
            >
              <p>{space.description}</p>
            </Card>
          </Link>
        ))}
      </div>
      <CyclesTable
        id={localStorage.getItem("tenant") || ""}
        cyclesBy="TENANT"
      />
    </div>
  );
}

export default TenantSpaces;
