import React from "react";
import { useState } from "react";
import { Modal, Table, TableProps, Button, Card, Space } from "antd";
import { EditOutlined, MenuOutlined } from "@ant-design/icons";
import { ProcedureForm } from "../forms/ProcedureForm";
import { gql, useMutation } from "@apollo/client";
import { CSS } from "@dnd-kit/utilities";
import type { DragEndEvent } from "@dnd-kit/core";
import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { StepsTable } from "./StepsTable";
import { CREATE_PROCEDURE, UPDATE_PROCEDURE } from "../../shared/mutations";
import Markdown from "react-markdown";

export interface ProcedureTableDataType {
  key: string;
  id: string;
  title: string;
  description: string;
  priority: string;
}

interface EntityTableProps {
  dataSource: any;
  title: string;
  processId: string;
}

export const UPDATE_PROCEDURE_ORDER = gql`
  mutation UpdateProcedureOrder($ids: [ID!]!) {
    updateProcedureOrder(ids: $ids)
  }
`;

export function useCreateProcedureMutation() {
  return useMutation(CREATE_PROCEDURE);
}

export function useUpdateProcedureMutation() {
  return useMutation(UPDATE_PROCEDURE);
}

export function useUpdateProcedureOrderMutation() {
  return useMutation(UPDATE_PROCEDURE_ORDER);
}

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  "data-row-key": string;
}

const Row = ({ children, ...props }: RowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props["data-row-key"],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    ...(isDragging ? { position: "relative", zIndex: 9999 } : {}),
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if ((child as React.ReactElement).key === "sort") {
          return React.cloneElement(child as React.ReactElement, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{ touchAction: "none", cursor: "move" }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

export const ProceduresTable: React.FC<EntityTableProps> = ({
  title,
  dataSource,
  processId,
}) => {
  const ModalStates = {
    OFF: "OFF",
    ADD: "ADD",
    EDIT: "EDIT",
  };
  const [tableSource, setTableSource] =
    useState<ProcedureTableDataType[]>(dataSource);
  const [modalState, setModalState] = useState(ModalStates.OFF);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const [createProcedureMutation, { data: createProcedureData }] =
    useCreateProcedureMutation();
  const [updateProcedureMutation, { data: updateProcedureData }] =
    useUpdateProcedureMutation();
  const [updateProcedureOrderMutation, { data: updateProcedureOrderData }] =
    useUpdateProcedureOrderMutation();

  const handleMouseEnter = (record: any) => {
    setHoveredRow(record.key);
  };

  const handleMouseLeave = () => {
    setHoveredRow(null);
  };

  const handleCancel = () => {
    setModalState(ModalStates.OFF);
  };

  const handleAddProcedure = () => {
    setModalState(ModalStates.ADD);
  };

  const handleEditProcedure = (entity: any) => {
    setSelectedRow(entity);
    setModalState(ModalStates.EDIT);
  };

  const handleSaveProcedure = async (procedure: any) => {
    if (modalState === ModalStates.ADD) {
      try {
        const result = await createProcedureMutation({
          variables: {
            title: procedure.title,
            description: procedure.description,
            processId: processId,
            priority: procedure.priority,
          },
        });

        const newData: ProcedureTableDataType = {
          key: result.data.createProcedure.id,
          id: result.data.createProcedure.id,
          title: result.data.createProcedure.title,
          priority: result.data.createProcedure.priority,
          description: result.data.createProcedure.description,
        };

        setTableSource([...tableSource, newData]);

        // Handle the result if needed
        console.log("Mutation result:", result);
      } catch (error) {
        // Handle the error if the mutation fails
        console.error("Mutation error:", error);
      }
    } else if (modalState === ModalStates.EDIT) {
      try {
        const result = await updateProcedureMutation({
          variables: {
            id: procedure.id, // Provide the procedure ID to identify which procedure to update
            title: procedure.title,
            description: procedure.description,
            priority: procedure.priority,
          },
        });

        setTableSource((tableSource) =>
          tableSource.map((item) =>
            item.id === procedure.id
              ? {
                  key: result.data.updateProcedure.id,
                  id: result.data.updateProcedure.id,
                  title: result.data.updateProcedure.title,
                  priority: result.data.updateProcedure.priority,
                  description: result.data.updateProcedure.description,
                }
              : item
          )
        );
        // Handle the result if needed
        console.log("Mutation result:", result);
      } catch (error) {
        // Handle the error if the mutation fails
        console.error("Mutation error:", error);
      }
    }
    setModalState(ModalStates.OFF);
  };

  const handleOrderProcedures = async (newTableSource: any) => {
    try {
      const result = await updateProcedureOrderMutation({
        variables: {
          ids: newTableSource.map((item: any) => item.id),
        },
      });
      // Handle the result if needed
      console.log("Mutation result:", result);
      return true;
    } catch (error) {
      // Handle the error if the mutation fails
      console.error("Mutation error:", error);
      return false;
    }
  };

  const TableHeader: React.FC<{ headerTitle: string }> = ({ headerTitle }) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px",
        }}
      >
        <div className="title">{headerTitle}</div>
        <Button onClick={handleAddProcedure}>New</Button>
      </div>
    );
  };

  const columns: TableProps<ProcedureTableDataType>["columns"] = [
    {
      key: "sort",
      width: 50,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
    },

    {
      title: "",
      key: "action",
      width: "100px",
      render: (record) => (
        <div style={{ height: "22px", display: "flex", alignItems: "center" }}>
          {hoveredRow === record.key && (
            <Button
              type="link"
              icon={<EditOutlined style={{ fontSize: "14px" }} />}
              onClick={() => handleEditProcedure(record)}
            />
          )}
        </div>
      ),
    },
  ];

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const newTableSource = () => {
        const activeIndex = tableSource.findIndex((i) => i.key === active.id);
        const overIndex = tableSource.findIndex((i) => i.key === over?.id);
        return arrayMove(tableSource, activeIndex, overIndex);
      };
      setTableSource(newTableSource());
      handleOrderProcedures(newTableSource());
    }
  };

  return (
    <div>
      <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext
          // rowKey array
          items={tableSource.map((i) => i.key)}
          strategy={verticalListSortingStrategy}
        >
          <Table
            bordered
            columns={columns}
            dataSource={tableSource}
            components={{
              body: {
                row: Row,
              },
            }}
            rowKey="key"
            onRow={(record) => ({
              onClick: () => {
                const keys = expandedRowKeys.includes(record.key)
                  ? expandedRowKeys.filter((key) => key !== record.key)
                  : [...expandedRowKeys, record.key];

                setExpandedRowKeys(keys);
              },
              onMouseEnter: () => handleMouseEnter(record),
              onMouseLeave: handleMouseLeave,
            })}
            expandedRowKeys={expandedRowKeys}
            title={() => <TableHeader headerTitle={title} />}
            pagination={{ position: [] }}
            expandable={{
              expandedRowRender: (record) => (
                <Space
                  direction="vertical"
                  size="small"
                  style={{
                    display: "flex",
                    paddingLeft: "50px",
                    paddingRight: "50px",
                  }}
                >
                  <div>
                    {record.description && (
                      <Card size="small">
                        <Markdown>{record.description}</Markdown>
                      </Card>
                    )}
                  </div>
                  <StepsTable
                    title="Steps"
                    procedureId={record.id ? record.id : ""}
                  />
                </Space>
              ),
              rowExpandable: (record) => true,
            }}
          />
        </SortableContext>
      </DndContext>
      <Modal
        zIndex={10000}
        title={
          modalState === ModalStates.ADD ? "Add Procedure" : "Edit Procedure"
        }
        open={modalState !== ModalStates.OFF}
        onCancel={handleCancel}
        footer={null}
      >
        <ProcedureForm
          onSave={handleSaveProcedure}
          onCancel={handleCancel}
          initialState={modalState === ModalStates.ADD ? "ADD" : "EDIT"}
          selectedRow={selectedRow}
        />
      </Modal>
    </div>
  );
};
