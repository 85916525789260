import { useParams } from "react-router-dom";

export function RedirectTo(): JSX.Element {
  const { redirect_to } = useParams();
  if (redirect_to) {
    window.location.replace(redirect_to);
  } else {
    // Handle the case where redirect_to is undefined
    console.error("Redirect URL is undefined");
  }
  return <div></div>;
}
