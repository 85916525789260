import {
  MDXEditor,
  listsPlugin,
  toolbarPlugin,
  UndoRedo,
  ListsToggle,
  BoldItalicUnderlineToggles,
  MDXEditorMethods,
} from "@mdxeditor/editor";
import "@mdxeditor/editor/style.css";
import { useEffect, useRef } from "react";

interface DescriptionProps {
  markdown: string;
  onChange: (markdown: string) => void;
}

// Add the Description component
export const Description: React.FC<DescriptionProps> = ({
  markdown,
  onChange,
}) => {
  const mdxEditorRef = useRef<MDXEditorMethods>(null);

  useEffect(() => {
    if (mdxEditorRef.current) {
      mdxEditorRef.current.setMarkdown(markdown);
    }
  }, [markdown]);

  return (
    <div
      style={{
        border: "1px solid #d9d9d9",
        borderRadius: "4px",
      }}
    >
      <MDXEditor
        ref={mdxEditorRef}
        markdown={markdown}
        plugins={[
          listsPlugin(),
          toolbarPlugin({
            toolbarContents: () => (
              <>
                {" "}
                <UndoRedo />
                <ListsToggle />
                <BoldItalicUnderlineToggles />
              </>
            ),
          }),
        ]}
        onChange={onChange}
      />
    </div>
  );
};
