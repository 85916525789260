import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ApolloProvider } from "@apollo/client";
import { initializeApp } from "firebase/app";
import { User, getAuth, onAuthStateChanged } from "firebase/auth";
import { signInWithGoogle } from "./auth/signin";
import { client } from "./pages/operations/client";
import { logToServer } from "./shared/utils";
import { addonSignInWithGoogle } from "./auth/addon-signin";

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyBgWZ96IM4WGCcy8HYzN5vl99UAWbjvYsc",
  authDomain: "bluetape-974bdb919d58.herokuapp.com",
  projectId: "workflows-414809",
  storageBucket: "workflows-414809.appspot.com",
  messagingSenderId: "416924528129",
  appId: "1:416924528129:web:c96ad19c509c82ae7e9a00",
};

const app = initializeApp(firebaseConfig);

function getCurrentUser(): Promise<User | null> {
  const auth = getAuth(app);
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(
      auth,
      (user) => {
        unsubscribe(); // Unsubscribe from the listener once we get the user data
        resolve(user);
      },
      reject
    );
  });
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const AuthenticatedApp = () => (
  <React.StrictMode>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </React.StrictMode>
);

const getUser = async (client: any) => {
  const queryParams = new URLSearchParams(window.location.search);
  const state = queryParams.get("state") || localStorage.getItem("state");
  console.log(
    "query params",
    queryParams.get("code"),
    queryParams.get("scope"),
    queryParams.get("prompt")
  );

  try {
    const user = await getCurrentUser();
    if (state) {
      const decodedState = JSON.parse(atob(state));
      logToServer("Decoded state", decodedState);
      const { token, callbackUrl } = decodedState;
      const redirectUrl = `${callbackUrl}?token=${encodeURIComponent(
        localStorage.getItem("token") || ""
      )}&state=${encodeURIComponent(token)}`;
      await addonSignInWithGoogle(client, app)
        .then(() => {
          logToServer("Redirecting to", { redirectUrl });
          window.location.href = redirectUrl;
        })
        .catch((error) => {
          logToServer("Redirecting to sign-in", error);
          root.render(<div>Redirecting to sign-in.</div>);
        });
    } else if (!user) {
      signInWithGoogle(client)
        .then(() => {
          // After successful sign-in, reload the page
          window.location.reload();
        })
        .catch((error) => {
          console.error("Sign-in error:", error);
          root.render(<div>Authentication error. Please try again.</div>);
        });
      root.render(<div>Logging in...</div>);
      return null;
    } else {
      await logToServer("User:", user);
      // Web App
      root.render(<AuthenticatedApp />);
    }
  } catch (error) {
    logToServer("Error in login", {
      error: (error as Error).toString(),
    });
    console.error("Authentication error:", error);
    root.render(<div>Authentication error. Please try again.</div>);
  }
};

getUser(client);
