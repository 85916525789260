import { useParams, Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { gql } from "graphql-tag";

import { Button, Modal, Table, TableProps, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { SpaceContext } from "../../contexts/SpaceContext";
import ProcessForm from "../forms/ProcessForm";
import { EditOutlined } from "@ant-design/icons";
import CyclesTable from "../tables/CyclesTable";
import { ProcessContext } from "../../contexts/ProcessContext";
import { Process, WorkPriority } from "../../gql/graphql";
import { createProcess, updateProcess } from "../operations/process";

// Define the GraphQL query
const GET_SPACE_INFO = gql`
  query GetSpaceInfo($spaceId: ID!) {
    spaceById(id: $spaceId) {
      title
      processes {
        id
        title
        priority
        description
        deadline_units
        deadline_uom
      }
    }
  }
`;

const ModalStates = {
  OFF: "OFF",
  ADD: "ADD",
  EDIT: "EDIT",
};

export function SpaceDetails() {
  const { process, setProcess } = React.useContext(ProcessContext);
  const [tableSource, setTableSource] = useState<DataType[]>([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [hoveredRow, setHoveredRow] = useState(null);
  const { space, setSpace } = React.useContext(SpaceContext);
  const [modalState, setModalState] = useState(ModalStates.OFF);
  const { spaceId } = useParams(); // Get the spaceId from the route parameters
  // Use the useQuery hook to fetch data
  const { loading, error, data } = useQuery(GET_SPACE_INFO, {
    variables: { spaceId },
  });

  useEffect(() => {
    // Effect logic here.
    // For example:
    if (!loading && !error) {
      setTableSource(
        data.spaceById.processes.map((process: Process) => ({
          key: process.id,
          title: process.title,
          priority: process.priority,
          description: process.description,
          deadline_uom: process.deadline_uom,
          deadline_units: process.deadline_units,
        }))
      );
      setProcess({ cProcessId: "", cProcessName: "" });

      setSpace({
        cSpaceId: spaceId,
        cSpaceName: data.spaceById.title,
      } as any);
    }
  }, [data, loading, error]); // Dependency array

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  interface DataType {
    key: string;
    title: string;
    priority: WorkPriority;
    description: string;
    deadline_uom: string;
    deadline_units: number;
  }

  const columns: TableProps<DataType>["columns"] = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (text, record) => {
        return (
          <Link to={`/process/${record.key}`} key={record.key}>
            {text}
          </Link>
        );
      },
    },
    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
    },

    {
      title: "",
      key: "action",
      width: "100px",
      render: (record) => (
        <div style={{ height: "22px", display: "flex", alignItems: "center" }}>
          {hoveredRow === record.key && (
            <Button
              type="link"
              icon={<EditOutlined style={{ fontSize: "14px" }} />}
              onClick={() => handleEditProcess(record)}
            />
          )}
        </div>
      ),
    },
  ];

  const handleMouseEnter = (record: any) => {
    setHoveredRow(record.key);
  };

  const handleMouseLeave = () => {
    setHoveredRow(null);
  };

  const handleAddProcess = () => {
    setModalState(ModalStates.ADD);
  };

  const handleEditProcess = (entity: any) => {
    setSelectedRow(entity);
    setModalState(ModalStates.EDIT);
  };

  const handleCancel = () => {
    setModalState(ModalStates.OFF);
  };

  const handleSaveProcess = async (process: any) => {
    if (modalState === ModalStates.ADD) {
      try {
        let withSpace = { ...process, spaceId: spaceId };
        const result = await createProcess(withSpace);

        const newData: DataType = {
          key: result.data.createProcess.id,
          title: result.data.createProcess.title,
          priority: result.data.createProcess.priority,
          description: result.data.createProcess.description,
          deadline_uom: result.data.createProcess.deadline_uom,
          deadline_units: result.data.createProcess.deadline_units,
        };

        setTableSource([...tableSource, newData]);

        // Handle the result if needed
        console.log("Mutation result:", result);
      } catch (error) {
        // Handle the error if the mutation fails
        console.error("Mutation error:", error);
      }
    } else if (modalState === ModalStates.EDIT) {
      try {
        const result = await updateProcess(process);
        if (result.data.updateProcess.id === process.id) {
          setTableSource((tableSource) =>
            tableSource.map((item) =>
              item.key === process.id
                ? {
                    key: result.data.updateProcess.id,
                    title: result.data.updateProcess.title,
                    priority: result.data.updateProcess.priority,
                    description: result.data.updateProcess.description,
                    deadline_units: result.data.updateProcess.deadline_units,
                    deadline_uom: result.data.updateProcess.deadline_uom,
                  }
                : item
            )
          );
          // Handle the result if needed
          console.log("Mutation result:", result);
        } else {
          console.error("Error updating process");
          throw new Error("Error updating process");
        }
      } catch (error) {
        // Handle the error if the mutation fails
        console.error("Mutation error:", error);
      }
    }
    setModalState(ModalStates.OFF);
  };

  const TableHeader: React.FC<{ headerTitle: string }> = ({ headerTitle }) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px",
        }}
      >
        <div className="title">{headerTitle}</div>
        <Button onClick={handleAddProcess}>New</Button>
      </div>
    );
  };

  const ProcessesTab = (
    <Tabs.TabPane tab="Processes" key="1">
      <div>
        <Table
          columns={columns}
          dataSource={tableSource}
          title={() => <TableHeader headerTitle={"Processes"} />}
          onRow={(record) => ({
            onMouseEnter: () => handleMouseEnter(record),
            onMouseLeave: handleMouseLeave,
          })}
        />
        <Modal
          zIndex={10000}
          title={
            modalState === ModalStates.ADD ? "Add Process" : "Edit Process"
          }
          open={modalState !== ModalStates.OFF}
          onCancel={handleCancel}
          footer={null}
        >
          <ProcessForm
            onSave={handleSaveProcess}
            onCancel={handleCancel}
            initialState={modalState === ModalStates.ADD ? "ADD" : "EDIT"}
            selectedRow={selectedRow}
          />
        </Modal>
      </div>
    </Tabs.TabPane>
  );
  const CyclesTab = (
    <Tabs.TabPane tab="Cycles" key="2">
      <CyclesTable id={spaceId ? spaceId : ""} cyclesBy="SPACE" />
    </Tabs.TabPane>
  );
  // Display the space details here
  return (
    <Tabs defaultActiveKey="2" type="line">
      {CyclesTab}
      {ProcessesTab}
    </Tabs>
  );
}

export default SpaceDetails;
