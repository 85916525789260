import React, { useEffect } from "react";
import { useState } from "react";
import { Modal, Table, TableProps, Button, Card } from "antd";
import { EditOutlined, DeleteOutlined, MenuOutlined } from "@ant-design/icons";
import { StepForm } from "../forms/StepForm";
import { gql, useMutation, useQuery } from "@apollo/client";
import { CSS } from "@dnd-kit/utilities";
import type { DragEndEvent } from "@dnd-kit/core";
import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CREATE_STEP, UPDATE_STEP } from "../../shared/mutations";
import DialogBox from "../../components/DialogBox";
import Markdown from "react-markdown";

export interface StepTableDataType {
  key: string;
  id: string;
  title: string;
  description: string;
}

interface EntityTableProps {
  title: string;
  procedureId: string;
}

const GET_PROCEDURE_STEPS = gql`
  query GetProcessSteps($procedureId: ID!) {
    procedureById(id: $procedureId) {
      title
      steps {
        id
        title
        description
      }
    }
  }
`;

export const UPDATE_STEP_ORDER = gql`
  mutation UpdateStepOrder($ids: [ID!]!) {
    updateStepOrder(ids: $ids)
  }
`;

export const DELETE_STEP = gql`
  mutation deleteStep($id: ID!) {
    deleteStep(id: $id)
  }
`;

export function useCreateStepMutation() {
  return useMutation(CREATE_STEP);
}

export function useUpdateStepMutation() {
  return useMutation(UPDATE_STEP);
}

export function useUpdateStepOrderMutation() {
  return useMutation(UPDATE_STEP_ORDER);
}

export function useDeleteStepMutation() {
  return useMutation(DELETE_STEP);
}

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  "data-row-key": string;
}

const Row = ({ children, ...props }: RowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props["data-row-key"],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    ...(isDragging ? { position: "relative", zIndex: 9999 } : {}),
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if ((child as React.ReactElement).key === "sort") {
          return React.cloneElement(child as React.ReactElement, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{ touchAction: "none", cursor: "move" }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

export const StepsTable: React.FC<EntityTableProps> = ({
  title,
  procedureId,
}) => {
  const ModalStates = {
    OFF: "OFF",
    ADD: "ADD",
    EDIT: "EDIT",
  };
  const [tableSource, setTableSource] = useState<StepTableDataType[]>([]);
  const [modalState, setModalState] = useState(ModalStates.OFF);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [selectedRow, setSelectedRow] = useState<StepTableDataType>();
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

  const [createStepMutation, { data: createStepData }] =
    useCreateStepMutation();
  const [updateStepMutation, { data: updateStepData }] =
    useUpdateStepMutation();
  const [updateStepOrderMutation, { data: updateStepOrderData }] =
    useUpdateStepOrderMutation();
  const [deleteStepMutation, { data: deleteStepData }] =
    useDeleteStepMutation();

  const { loading, error, data } = useQuery(GET_PROCEDURE_STEPS, {
    variables: { procedureId: procedureId },
  });

  useEffect(() => {
    if (!loading && !error) {
      setTableSource(
        data.procedureById.steps.map((step: any) => ({
          key: step.id,
          id: step.id,
          title: step.title,
          description: step.description,
        })) || []
      );
    }
  }, [data, loading, error]);

  const handleMouseEnter = (record: any) => {
    setHoveredRow(record.key);
  };

  const handleMouseLeave = () => {
    setHoveredRow(null);
  };

  const handleCancel = () => {
    setModalState(ModalStates.OFF);
  };

  const handleAddStep = () => {
    setModalState(ModalStates.ADD);
  };

  const handleEditStep = (entity: any) => {
    setSelectedRow(entity);
    setModalState(ModalStates.EDIT);
  };

  const handleDeleteStepClick = (entity: any) => {
    setSelectedRow(entity);
    setDeleteDialogVisible(true);
  };

  const handleDeleteStep = async (step: any) => {
    try {
      const result = await deleteStepMutation({
        variables: {
          id: step.id,
        },
      });

      setTableSource(tableSource.filter((row) => row.key !== step.id));

      // Handle the result if needed
      console.log("Mutation result:", result);
      return true;
    } catch (error) {
      // Handle the error if the mutation fails
      console.error("Mutation error:", error);
      return false;
    }
  };

  const handleSaveStep = async (step: any) => {
    if (modalState === ModalStates.ADD) {
      try {
        const result = await createStepMutation({
          variables: {
            title: step.title,
            description: step.description,
            procedureId: procedureId,
          },
        });

        const newData: StepTableDataType = {
          key: result.data.createStep.id,
          id: result.data.createStep.id,
          title: result.data.createStep.title,
          description: result.data.createStep.description,
        };

        setTableSource([...tableSource, newData]);

        // Handle the result if needed
        console.log("Mutation result:", result);
      } catch (error) {
        // Handle the error if the mutation fails
        console.error("Mutation error:", error);
      }
    } else if (modalState === ModalStates.EDIT) {
      try {
        const result = await updateStepMutation({
          variables: {
            id: step.id, // Provide the step ID to identify which step to update
            title: step.title,
            description: step.description,
          },
        });

        setTableSource((tableSource) =>
          tableSource.map((item) =>
            item.id === step.id
              ? {
                  key: result.data.updateStep.id,
                  id: result.data.updateStep.id,
                  title: result.data.updateStep.title,
                  description: result.data.updateStep.description,
                }
              : item
          )
        );
        // Handle the result if needed
        console.log("Mutation result:", result);
      } catch (error) {
        // Handle the error if the mutation fails
        console.error("Mutation error:", error);
      }
    }
    setModalState(ModalStates.OFF);
  };

  const handleOrderSteps = async (newTableSource: any) => {
    try {
      const result = await updateStepOrderMutation({
        variables: {
          ids: newTableSource.map((item: any) => item.id),
        },
      });
      // Handle the result if needed
      console.log("Mutation result:", result);
      return true;
    } catch (error) {
      // Handle the error if the mutation fails
      console.error("Mutation error:", error);
      return false;
    }
  };

  const TableHeader: React.FC<{ headerTitle: string }> = ({ headerTitle }) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px",
        }}
      >
        <div className="title">{headerTitle}</div>
        <Button onClick={handleAddStep}>New</Button>
      </div>
    );
  };

  const columns: TableProps<StepTableDataType>["columns"] = [
    {
      key: "sort",
      width: 50,
      align: "center",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "",
      key: "action",
      width: 100,
      render: (record) => {
        return (
          hoveredRow === record.key && (
            <div
              style={{ height: "22px", display: "flex", alignItems: "center" }}
            >
              <Button
                type="link"
                icon={<EditOutlined style={{ fontSize: "14px" }} />}
                onClick={() => handleEditStep(record)}
              />
              <Button
                type="link"
                icon={<DeleteOutlined style={{ fontSize: "14px" }} />}
                onClick={() => handleDeleteStepClick(record)}
              />
            </div>
          )
        );
      },
    },
  ];

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const newTableSource = () => {
        const activeIndex = tableSource.findIndex((i) => i.key === active.id);
        const overIndex = tableSource.findIndex((i) => i.key === over?.id);
        return arrayMove(tableSource, activeIndex, overIndex);
      };
      setTableSource(newTableSource());
      handleOrderSteps(newTableSource());
    }
  };

  return (
    <div>
      <Modal
        zIndex={10000}
        title={modalState === ModalStates.ADD ? "Add Step" : "Edit Step"}
        open={modalState !== ModalStates.OFF}
        onCancel={handleCancel}
        footer={null}
      >
        <StepForm
          onSave={handleSaveStep}
          onCancel={handleCancel}
          initialState={modalState === ModalStates.ADD ? "ADD" : "EDIT"}
          selectedRow={selectedRow}
        />
      </Modal>
      <DialogBox
        severity="Danger"
        title="Delete Step"
        description={`Are you sure you want to delete the ${
          selectedRow?.title ?? ""
        } step?`}
        confirmButtonText="Delete"
        executeFunction={() => {
          handleDeleteStep(selectedRow);
        }}
        visible={deleteDialogVisible} // Pass the visibility state
        setVisible={setDeleteDialogVisible} // Pass the function to set visibility
      />
      <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext
          // rowKey array
          items={tableSource.map((i) => i.key)}
          strategy={verticalListSortingStrategy}
        >
          <style>
            {`
          .hide-expand-icon-column .ant-table-row-expand-icon-cell {
            display: none;
          }
        `}
          </style>
          <Table
            //className="hide-expand-icon-column"
            bordered
            size="small"
            title={() => <TableHeader headerTitle={title} />}
            columns={columns}
            dataSource={tableSource}
            components={{
              body: {
                row: Row,
              },
            }}
            rowKey="key"
            pagination={{ position: [] }}
            onRow={(record) => ({
              onClick: () => {
                const keys = expandedRowKeys.includes(record.key)
                  ? expandedRowKeys.filter((key) => key !== record.key)
                  : [...expandedRowKeys, record.key];

                setExpandedRowKeys(keys);
              },
              onMouseEnter: () => handleMouseEnter(record),
              onMouseLeave: handleMouseLeave,
            })}
            expandedRowKeys={expandedRowKeys}
            expandable={{
              expandedRowRender: (record) => (
                <div>
                  {record.description && (
                    <Card size="small">
                      <Markdown>{record.description}</Markdown>
                    </Card>
                  )}
                </div>
              ),
              rowExpandable: (record) => true,
            }}
          />
        </SortableContext>
      </DndContext>
    </div>
  );
};
