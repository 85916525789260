import { useState, useEffect } from "react";
import { Form, Input, Button, Select } from "antd";
import { WorkPriority } from "../../gql/graphql";
import { Description } from "../../components/Description";
import { TimePeriod } from "../../components/TimePeriod";
import { useLazyQuery, useQuery } from "@apollo/client";
import { GET_PROCESS_WIZARD } from "../../shared/queries";
import { get } from "lodash";
import ButtonGroup from "antd/es/button/button-group";

const { Option } = Select;
interface ProcessFormProps {
  onSave: (values: any) => void;
  onCancel: () => void;
  initialState: "ADD" | "EDIT"; // Specify the possible values for initialState
  selectedRow: any; // Add selectedRow prop
}

export const ProcessForm: React.FC<ProcessFormProps> = ({
  onSave,
  onCancel,
  initialState,
  selectedRow,
}) => {
  const [form] = Form.useForm();
  const [isEditing, setIsEditing] = useState(initialState === "EDIT");
  const [
    getProcessWizard,
    { data: processWizardData, loading: processWizardLoading },
  ] = useLazyQuery(GET_PROCESS_WIZARD);

  useEffect(() => {
    if (initialState === "EDIT" && selectedRow) {
      // If in edit mode and selectedRow exists, pre-populate form fields with selectedRow data
      form.setFieldsValue({
        id: selectedRow.key || selectedRow.id,
        title: selectedRow.title,
        priority: selectedRow.priority,
        description: selectedRow.description,
        deadline_uom: selectedRow.deadline_uom,
        deadline_units: selectedRow.deadline_units,

        // Add other fields as needed
      });
    } else {
      form.setFieldsValue({
        id: null,
        title: "",
        priority: WorkPriority.Medium,
        description: "",
        deadline_uom: null,
        deadline_units: null,
        json: null,
        // Add other fields as needed
      });
    }
  }, [initialState, selectedRow]);

  const handleSave = async (withAI: boolean) => {
    try {
      if (withAI) {
        const wizard = await getProcessWizard({
          variables: {
            title: form.getFieldValue("title"),
            description: form.getFieldValue("description"),
          },
        });
        if (!processWizardLoading && wizard.data.getProcessWizardJson) {
          const json = wizard.data.getProcessWizardJson.json;
          form.setFieldValue("json", json);
          // Use the json data as needed
        }
      }
      const values = await form.validateFields();
      onSave(values);
      onCancel();
    } catch (error) {
      console.error("Validation error:", error);
    }
  };

  const handleDeadlineChange = (
    units: number | undefined,
    uom: string | undefined
  ) => {
    form.setFieldsValue({
      deadline_uom: uom,
      deadline_units: units,
    });
  };

  return (
    <Form form={form} layout="vertical">
      <Form.Item name="id" style={{ display: "none" }}>
        <Input type="hidden" />
      </Form.Item>
      <Form.Item name="deadline_uom" style={{ display: "none" }}>
        <Input type="hidden" />
      </Form.Item>
      <Form.Item name="deadline_units" style={{ display: "none" }}>
        <Input type="hidden" />
      </Form.Item>
      <Form.Item name="json" style={{ display: "none" }}>
        <Input type="hidden" />
      </Form.Item>
      <Form.Item
        label="Title"
        name="title"
        rules={[{ required: true, message: "Please enter a title" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Description" name="description" rules={[]}>
        <Description
          markdown={
            initialState === "EDIT" ? selectedRow?.description ?? " " : " "
          }
          onChange={(markdown) => {
            form.setFieldsValue({ description: markdown });
          }}
        />
      </Form.Item>
      <Form.Item
        label="Default Priority"
        name="priority"
        initialValue="Medium" // Set the default value to "Medium"
        rules={[{ required: true, message: "Please select a priority" }]}
      >
        <Select>
          <Option value="Low">Low</Option>
          <Option value="Medium">Medium</Option>
          <Option value="High">High</Option>
          <Option value="Critical">Critical</Option>
        </Select>
      </Form.Item>
      <Form.Item label="Default Deadline (optional)" name="timePeriod">
        <TimePeriod
          units={selectedRow.deadline_units}
          uom={selectedRow.deadline_uom}
          onChange={handleDeadlineChange}
        />
      </Form.Item>
      <Form.Item>
        <Button onClick={onCancel} style={{ marginRight: 8 }}>
          Cancel
        </Button>
        <ButtonGroup>
          {!isEditing && (
            <Button type="default" onClick={() => handleSave(true)}>
              Add with AI
            </Button>
          )}
          <Button type="primary" onClick={() => handleSave(false)}>
            {isEditing ? "Save Changes" : "Add Process"}
          </Button>
        </ButtonGroup>
      </Form.Item>
    </Form>
  );
};

export default ProcessForm;
