import { gql, useQuery } from "@apollo/client";

// Define the GraphQL query
const RETRIEVE_GMAIL_INBOX = gql`
  query RetrieveGmailInbox {
    retrieveGmailInbox
  }
`;

export function GmailIInbox(): JSX.Element {
  const { loading, error, data, refetch } = useQuery(RETRIEVE_GMAIL_INBOX);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return <div>{data.retrieveGmailInbox.result}</div>;
}
