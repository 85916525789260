import { Select } from "antd";
import { useState } from "react";

const daysOfMonthOptions: any = [];
for (let i = 1; i <= 31; i++) {
  daysOfMonthOptions.push({
    value: i,
    label: i,
  });
}

const DaysOfMonth: React.FC<{
  days: number[];
  onChange: (checkedValues: number[]) => void;
}> = ({ days, onChange }) => {
  const [selectedDays, setSelectedDays] = useState(days);

  const handleChange = (checkedValues: number[]) => {
    // sort the checked values

    checkedValues.sort((a, b) => a - b);
    setSelectedDays(checkedValues);
    if (typeof onChange === "function") {
      onChange(checkedValues);
    }
  };

  return (
    <Select
      mode="multiple"
      options={daysOfMonthOptions}
      value={selectedDays}
      dropdownStyle={{ maxWidth: "100px" }}
      onChange={handleChange}
    />
  );
};

export default DaysOfMonth;
