import { useState, useEffect } from "react";
import { Form, Input, Button, Select, DatePicker } from "antd";
import { WorkPriority, WorkStatus } from "../../gql/graphql";
import { Description } from "../../components/Description";
import { formatISO, parseISO } from "date-fns";
import dateFnsGenerateConfig from "rc-picker/lib/generate/dateFns";
const FnDatePicker = DatePicker.generatePicker<Date>(dateFnsGenerateConfig);

const { Option } = Select;
interface ProcessCycleFormProps {
  onSave: (values: any) => void;
  onCancel: () => void;
  initialState: "ADD" | "EDIT"; // Specify the possible values for initialState
  selectedRow: any; // Add selectedRow prop
}

export const ProcessCycleForm: React.FC<ProcessCycleFormProps> = ({
  onSave,
  onCancel,
  initialState,
  selectedRow,
}) => {
  const [form] = Form.useForm();
  const [isEditing, setIsEditing] = useState(initialState === "EDIT");

  useEffect(() => {
    if (initialState === "EDIT" && selectedRow) {
      // If in edit mode and selectedRow exists, pre-populate form fields with selectedRow data
      form.setFieldsValue({
        id: selectedRow.key,
        title: selectedRow.cycle,
        status: selectedRow.status,
        priority: selectedRow.priority,
        deadline: selectedRow.deadline,
        description: selectedRow.description || "",
        // Add other fields as needed
      });
    } else {
      form.setFieldsValue({
        id: null,
        title: "",
        priority: WorkPriority.Medium,
        description: "",
        status: WorkStatus.ToDo,
        deadline: null,

        // Add other fields as needed
      });
    }
  }, [initialState, selectedRow]);

  const handleSave = async () => {
    try {
      const values = await form.validateFields();
      onSave(values);
      onCancel();
    } catch (error) {
      console.error("Validation error:", error);
    }
  };

  const dateFormat = "YYYY-MM-DD HH:mm";

  return (
    <Form form={form} layout="vertical">
      <Form.Item name="id" style={{ display: "none" }}>
        <Input type="hidden" />
      </Form.Item>
      <Form.Item name="status" style={{ display: "none" }}>
        <Input type="hidden" />
      </Form.Item>
      <Form.Item name="deadline" style={{ display: "none" }}>
        <Input type="hidden" />
      </Form.Item>
      <Form.Item
        label="Title"
        name="title"
        rules={[{ required: true, message: "Please enter a title" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Description" name="description" rules={[]}>
        <Description
          markdown={initialState === "EDIT" ? selectedRow.description : ""}
          onChange={(markdown: string) => {
            form.setFieldsValue({ description: markdown });
          }}
        />
      </Form.Item>
      <Form.Item
        label="Default Priority"
        name="priority"
        initialValue="Medium" // Set the default value to "Medium"
        rules={[{ required: true, message: "Please select a priority" }]}
      >
        <Select>
          <Option value="Low">Low</Option>
          <Option value="Medium">Medium</Option>
          <Option value="High">High</Option>
          <Option value="Critical">Critical</Option>
        </Select>
      </Form.Item>
      <Form.Item label="Deadline_date" name="deadline_date">
        <FnDatePicker
          value={
            selectedRow?.deadline ? parseISO(selectedRow.deadline) : undefined
          }
          format={dateFormat}
          showTime={{ format: "HH:mm" }}
          onCalendarChange={(date, dateString) => {
            form.setFieldsValue({
              deadline_date: parseISO(dateString as string),
              deadline: dateString,
            });
          }}
        />
      </Form.Item>
      <Form.Item>
        <Button type="primary" onClick={handleSave}>
          {isEditing ? "Save Changes" : "Add Cycle"}
        </Button>
        <Button onClick={onCancel} style={{ marginLeft: 8 }}>
          Cancel
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ProcessCycleForm;
