import React, { useState } from "react";
import { Input, Select } from "antd";

interface TimePeriodProps {
  units?: number | undefined;
  uom?: string | undefined;
  onChange?: (units: number | undefined, uom: string | undefined) => void;
}

export const TimePeriod: React.FC<TimePeriodProps> = ({
  units,
  uom,
  onChange,
}) => {
  const [getUnits, setUnits] = useState<number | undefined>(units);
  const [getUom, setUom] = useState<string | undefined>(uom);

  // useEffect(() => {
  //   setUnits(units);
  //   setUom(uom);
  // }, [units, uom]);

  const onUnitsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const new_units = e.target.value ? parseInt(e.target.value, 10) : undefined;
    if (Number.isNaN(new_units)) {
      return;
    }

    setUnits(new_units);
    onChange?.(new_units, getUom);
  };

  const onUomChange = (newUom: string) => {
    setUom(newUom);
    onChange?.(getUnits, newUom);
  };

  return (
    <span>
      <Input
        type="text"
        placeholder="3"
        value={getUnits}
        onChange={onUnitsChange}
        style={{ width: 100 }}
      />
      <Select
        value={getUom}
        style={{ width: 80, margin: "0 8px" }}
        placeholder={"Days"}
        onChange={onUomChange}
      >
        <Select value="Hours" />
        <Select value="Days" />
        <Select value="Weeks" />
        <Select value="Months" />
        <Select value="Years" />
      </Select>
    </span>
  );
};
