import React from "react";

export interface SpaceContextProps {
  space: {
    cSpaceId: string;
    cSpaceName: string;
  };
  setSpace: React.Dispatch<
    React.SetStateAction<{ cSpaceId: string; cSpaceName: string }>
  >;
}

export const SpaceContext = React.createContext<SpaceContextProps>({
  space: {
    cSpaceId: "",
    cSpaceName: "",
  },
  setSpace: () => {},
});
