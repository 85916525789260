import React, { ReactNode, useState } from "react";
import { Layout, Breadcrumb } from "antd";
import {
  HomeOutlined,
  UserOutlined,
  AppstoreAddOutlined,
} from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";
import { SpaceContext } from "./contexts/SpaceContext";
import { ProcessContext } from "./contexts/ProcessContext";

const { Sider, Header, Content } = Layout;

interface AppLayoutProps {
  children: ReactNode;
  pageTitle: string;
}

export const AppLayout: React.FC<AppLayoutProps> = ({
  children,
  pageTitle,
}) => {
  const { space, setSpace } = React.useContext(SpaceContext);
  const { process, setProcess } = React.useContext(ProcessContext);
  const breadcrumbItems = [];

  breadcrumbItems.push(
    <Breadcrumb.Item key="home">
      <Link to="/">
        <HomeOutlined />
      </Link>
    </Breadcrumb.Item>
  );

  if (space.cSpaceName) {
  }
  if (process.cProcessName) {
    breadcrumbItems.push(
      <Breadcrumb.Item key="space">
        <Link to={"/space/" + space.cSpaceId}>{space.cSpaceName}</Link>
      </Breadcrumb.Item>
    );
  }

  return (
    <Layout style={{ height: "100%" }}>
      <Header
        style={{
          background: "#fff",
          lineHeight: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          justifyContent: "start",
        }}
      >
        <div style={{ marginTop: "8px" }}>
          <Breadcrumb>{breadcrumbItems}</Breadcrumb>

          <h2>{pageTitle}</h2>
        </div>
      </Header>

      <Content style={{ margin: "16px" }}>
        {/*
            Use startTransition to control rendering priority if needed
            startTransition(() => {
              {children}
            });
          */}
        {children}
      </Content>
    </Layout>
  );
};

export default AppLayout;
