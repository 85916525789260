import { useState, useEffect } from "react";
import { Form, Button, Select, DatePicker } from "antd";
import DaysOfWeek from "../../components/DaysOfWeek";
import dateFnsGenerateConfig from "rc-picker/lib/generate/dateFns";
import { format, parse } from "date-fns";
import DaysOfMonth from "../../components/DaysOfMonth";
const FnDatePicker = DatePicker.generatePicker<Date>(dateFnsGenerateConfig);

interface TriggerScheduleFormProps {
  onSave: (values: any) => void;
  onCancel: () => void;
  initialState: "ADD" | "EDIT"; // Specify the possible values for initialState
  data: any; // Add data prop
}

export const TriggerScheduleForm: React.FC<TriggerScheduleFormProps> = ({
  onSave,
  onCancel,
  initialState,
  data,
}) => {
  const [form] = Form.useForm();
  const [isEditing, setIsEditing] = useState(initialState === "EDIT");
  const [frequency, setFrequency] = useState(
    data.triggerSchedule?.frequency ?? "Daily"
  );
  const [time, setTime] = useState(
    data.triggerSchedule?.timeOfDay?.length > 0
      ? data.triggerSchedule.timeOfDay[0]
      : "00:00:00"
  );
  const [monthOfYear, setMonthOfYear] = useState(
    data.triggerSchedule?.monthOfYear.map((month: string) => {
      return parse(month, "MMMM", new Date());
    }) ?? []
  );

  useEffect(() => {
    if (initialState === "EDIT" && data) {
      // If in edit mode and data exists, pre-populate form fields with data data
      form.setFieldsValue({
        processId: data.id,
        triggerId: data.triggerSchedule.id,
        frequency: data.triggerSchedule.frequency,
        dayOfWeek: data.triggerSchedule.dayOfWeek || [],
        dayOfMonth: data.triggerSchedule.dayOfMonth || [],
        monthOfYear: data.triggerSchedule.monthOfYear || [],
        timeOfDay: data.triggerSchedule.timeOfDay,
        // Add other fields as needed
      });
      setIsEditing(true);
    } else {
      form.setFieldsValue({
        processId: data.id,
        triggerId: data.triggerSchedule?.id,
        frequency: "Daily",
        dayOfWeek: [],
        dayOfMonth: [],
        monthOfYear: [],
        timeOfDay: ["00:00:00"],

        // Add other fields as needed
      });
    }
  }, [initialState, data]);

  if (!data) {
    return null;
  }

  const handleSave = async () => {
    try {
      const values = await form.validateFields();
      onSave(values);
      onCancel();
    } catch (error) {
      console.error("Validation error:", error);
    }
  };

  const buildTIme = () => {
    let [hours, minutes, seconds] = time.split(":").map(Number);
    let date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(seconds);
    return date;
  };

  let timevar = buildTIme();

  // buildMonth uses the monthOfYear array to build an array of Date where each item in the array is a date for the first day of the month with the current year

  const handleTimeChange = (newTime: Date) => {
    let hours = newTime.getHours().toString().padStart(2, "0"); // get hours
    let minutes = newTime.getMinutes().toString().padStart(2, "0"); // get minutes
    let seconds = newTime.getSeconds().toString().padStart(2, "0"); // get seconds
    let timeOfDay = `${hours}:${minutes}:${seconds}`;
    form.setFieldsValue({ timeOfDay: [timeOfDay] });
    setTime(timeOfDay);
  };

  const handleDayOfWeekChange = (days: string[]) => {
    console.log("Days of the week:", days);
    form.setFieldsValue({ dayOfWeek: days });
  };

  const handleDayOfMonthChange = (days: number[]) => {
    console.log("Days of the month:", days);
    form.setFieldsValue({ dayOfMonth: days });
  };

  const handleMonthOfYearChange = (months: Date[]) => {
    console.log("Months of the year:", months);
    setMonthOfYear(months);
    let moy: string[] =
      months.map((month) => format(month, "MMMM").toUpperCase()) || [];

    form.setFieldsValue({ monthOfYear: moy });
  };

  return (
    <div>
      <style>
        {`
      .ant-picker-panel .ant-picker-year-btn {
        display: "none",
      }
    `}
      </style>
      <Form form={form} layout="vertical">
        <Form.Item name="processId" style={{ display: "none" }}>
          <input type="hidden" />
        </Form.Item>
        <Form.Item name="triggerId" style={{ display: "none" }}>
          <input type="hidden" />
        </Form.Item>
        <Form.Item name="timeOfDay" style={{ display: "none" }}>
          <input type="hidden" />
        </Form.Item>
        <Form.Item name="dayOfWeek" style={{ display: "none" }}>
          <input type="hidden" />
        </Form.Item>
        <Form.Item name="dayOfMonth" style={{ display: "none" }}>
          <input type="hidden" />
        </Form.Item>
        <Form.Item name="monthOfYear" style={{ display: "none" }}>
          <input type="hidden" />
        </Form.Item>
        <Form.Item
          label="Frequency"
          name="frequency"
          initialValue="Daily" // Set the default value to "Medium"
          rules={[{ required: true, message: "Please select frequency" }]}
        >
          <Select onChange={(value) => setFrequency(value)}>
            <Select value="Daily" />
            <Select value="Weekly" />
            <Select value="Monthly" />
            <Select value="Yearly" />
          </Select>
        </Form.Item>
        {frequency === "Weekly" && (
          <Form.Item label="Days of the Week" name="dayOfWeek">
            <DaysOfWeek
              days={form.getFieldValue("dayOfWeek")}
              onChange={handleDayOfWeekChange}
            />
          </Form.Item>
        )}
        {frequency === "Yearly" && (
          <Form.Item label="Months of the year">
            <FnDatePicker
              value={monthOfYear}
              picker="month"
              onChange={handleMonthOfYearChange}
              placeholder="Select months"
              multiple
              format="MMMM"
            />
          </Form.Item>
        )}
        {(frequency === "Monthly" || frequency === "Yearly") && (
          <Form.Item label="Days of the Month" name="dayOfMonth">
            <DaysOfMonth
              days={form.getFieldValue("dayOfMonth")}
              onChange={handleDayOfMonthChange}
            />
          </Form.Item>
        )}

        <Form.Item label="Time">
          <FnDatePicker
            value={timevar} //
            picker="time"
            onChange={handleTimeChange}
            format="HH:mm"
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" onClick={handleSave}>
            {isEditing ? "Save Changes" : "Add Schedule"}
          </Button>
          <Button onClick={onCancel} style={{ marginLeft: 8 }}>
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default TriggerScheduleForm;
