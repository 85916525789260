import { useState, useEffect } from "react";
import { Form, Input, Button } from "antd";
import { Description } from "../../components/Description";

interface StepFormProps {
  onSave: (values: any) => void;
  onCancel: () => void;
  initialState: "ADD" | "EDIT"; // Specify the possible values for initialState
  selectedRow: any; // Add selectedRow prop
}

export const StepForm: React.FC<StepFormProps> = ({
  onSave,
  onCancel,
  initialState,
  selectedRow,
}) => {
  const [form] = Form.useForm();
  const [isEditing, setIsEditing] = useState(initialState === "EDIT");

  useEffect(() => {
    if (initialState === "EDIT" && selectedRow) {
      // If in edit mode and selectedRow exists, pre-populate form fields with selectedRow data
      form.setFieldsValue({
        id: selectedRow.id,
        title: selectedRow.title,
        desciption: selectedRow.description,
        // Add other fields as needed
      });
      setIsEditing(true);
    } else {
    }
  }, [initialState, selectedRow]);

  const handleSave = async () => {
    try {
      const values = await form.validateFields();
      onSave(values);
      onCancel();
    } catch (error) {
      console.error("Validation error:", error);
    }
  };

  return (
    <div>
      <Form form={form} layout="vertical">
        <Form.Item name="id" style={{ display: "none" }}>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item
          label="Title"
          name="title"
          rules={[{ required: true, message: "Please enter a title" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Description" name="description" rules={[{}]}>
          <Description
            markdown={isEditing ? selectedRow.description : ""}
            onChange={(markdown) => {
              form.setFieldsValue({ description: markdown });
            }}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" onClick={handleSave}>
            {isEditing ? "Save Changes" : "Add Step"}
          </Button>
          <Button onClick={onCancel} style={{ marginLeft: 8 }}>
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default StepForm;
