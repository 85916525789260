import { Menu, Tag } from "antd";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { WorkStatus } from "../gql/graphql";
import MenuItem from "antd/es/menu/MenuItem";
import { useState } from "react";

const options = Object.keys(WorkStatus).map((key: any, index) => ({
  key: key,
  label: Object.values(WorkStatus)[index] ?? key,
}));

interface ProcessCycleStatusProps {
  processCycleId: string;
  defaultValue: any;
  onSelectStatus: (ids: string[], status: string) => void;
  size?: SizeType;
}

interface ProcedureCycleStatusProps {
  procedureCycleId: string;
  defaultValue: any;
  onSelectStatus: (ids: string[], status: string) => void;
  size?: SizeType;
}

interface StepCycleStatusProps {
  stepCycleId: string;
  defaultValue: any;
  onSelectStatus: (ids: string[], status: string) => void;
  size?: SizeType;
}

const tagColor = (status: string) => {
  switch (status) {
    case "To_Do":
      return "";
    case "IN_PROGRESS":
      return "green";
    case "PAUSED":
      return "orange";
    case "SKIPPED":
      return "red";
    case "DONE":
      return "blue";
    default:
      return "";
  }
};

export const SelectProcessCycleStatus: React.FC<ProcessCycleStatusProps> = ({
  processCycleId,
  defaultValue,
  onSelectStatus,
  size = "middle",
}) => {
  console.log("defaultValue", defaultValue);
  console.log("options", options);
  const [isMenuVisible, setMenuVisible] = useState(false);

  if (defaultValue === "DONE") {
    return <span />;
  } else
    return (
      <div>
        <Tag
          color={tagColor(defaultValue)}
          onClick={() => setMenuVisible(!isMenuVisible)}
        >
          {defaultValue.replace("_", " ")}
        </Tag>
        {isMenuVisible && (
          <Menu
            onMouseLeave={() => setMenuVisible(false)}
            style={{ position: "absolute", zIndex: 1000 }}
          >
            {options.map(
              (option) =>
                option.label != defaultValue && (
                  <MenuItem
                    key={option.key}
                    onClick={() => {
                      onSelectStatus([processCycleId], option.label);
                      setMenuVisible(false); // Hide the menu after an option is selected
                    }}
                    style={{ marginBlock: 0 }}
                  >
                    {option.label.replace("_", " ")}
                  </MenuItem>
                )
            )}
          </Menu>
        )}
      </div>
    );
};

export const SelectProcedureCycleStatus: React.FC<
  ProcedureCycleStatusProps
> = ({ procedureCycleId, defaultValue, onSelectStatus, size = "middle" }) => {
  console.log("defaultValue", defaultValue);
  console.log("options", options);
  const [isMenuVisible, setMenuVisible] = useState(false);

  if (defaultValue === "DONE") {
    return <span />;
  } else
    return (
      <div>
        <Tag
          color={tagColor(defaultValue)}
          onClick={() => setMenuVisible(!isMenuVisible)}
        >
          {defaultValue.replace("_", " ")}
        </Tag>
        {isMenuVisible && (
          <Menu
            onMouseLeave={() => setMenuVisible(false)}
            style={{ position: "absolute", zIndex: 1000 }}
          >
            {options.map(
              (option) =>
                option.label != defaultValue && (
                  <MenuItem
                    key={option.key}
                    onClick={() => {
                      onSelectStatus([procedureCycleId], option.label);
                      setMenuVisible(false); // Hide the menu after an option is selected
                    }}
                    style={{ marginBlock: 0 }}
                  >
                    {option.label.replace("_", " ")}
                  </MenuItem>
                )
            )}
          </Menu>
        )}
      </div>
    );
};

export const SelectStepCycleStatus: React.FC<StepCycleStatusProps> = ({
  stepCycleId,
  defaultValue,
  onSelectStatus,
  size = "middle",
}) => {
  console.log("defaultValue", defaultValue);
  console.log("options", options);
  const [isMenuVisible, setMenuVisible] = useState(false);
  if (defaultValue === "DONE") {
    return <span />;
  } else
    return (
      <div onClick={(e) => e.stopPropagation()}>
        <Tag
          color={tagColor(defaultValue)}
          onClick={() => setMenuVisible(!isMenuVisible)}
        >
          {defaultValue.replace("_", " ")}
        </Tag>
        {isMenuVisible && (
          <Menu
            onMouseLeave={() => setMenuVisible(false)}
            style={{ position: "absolute", zIndex: 1000 }}
          >
            {options.map(
              (option) =>
                option.label != defaultValue && (
                  <MenuItem
                    key={option.key}
                    onClick={() => {
                      onSelectStatus([stepCycleId], option.label);
                      setMenuVisible(false); // Hide the menu after an option is selected
                    }}
                    style={{ marginBlock: 0 }}
                  >
                    {option.label.replace("_", " ")}
                  </MenuItem>
                )
            )}
          </Menu>
        )}
      </div>
    );
};
