export async function logToServer(
  message: string,
  data?: Record<string, any>
): Promise<void> {
  try {
    const response = await fetch("/api/log", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ message, data }),
    });

    if (!response.ok) {
      //console.error("Failed to send log to server:", response.statusText);
      console.log(message, JSON.stringify(data));
    }
  } catch (error) {
    console.error("Error sending log to server:", error);
  }
}
